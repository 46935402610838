import React from 'react'

import Header from '~components/Header'
import Footer from '~components/Footer'

import PrivacyTermsHero from '~components/pages/privacy-terms/Hero'
import PrivacyTermsPrivacy from '~components/pages/privacy-terms/Privacy'
import PrivacyTermsTerms from '~components/pages/privacy-terms/Terms'


const PrivacyTermsPageWrap = ({ data, isPreview }) => {
  return (
    <>
      <Header isPreview={isPreview} />
      <PrivacyTermsHero data={data.hero} isPreview={isPreview} />
      <PrivacyTermsTerms data={data.terms} isPreview={isPreview} />
      <PrivacyTermsPrivacy data={data.privacy} isPreview={isPreview} />
      <Footer isPreview={isPreview} />
    </>
  )
}

export default PrivacyTermsPageWrap
