import React from 'react'

import Layout from '~global/Layout'
import PrivacyTermsPageWrap from '~pageWraps/PrivacyTermsPageWrap'

import data from '../content/privacy-terms.yaml'

const PrivacyTerms = () => {
  return (
    <Layout>
      <PrivacyTermsPageWrap data={data} />
    </Layout>
  )
}

export default PrivacyTerms
