import React from 'react'
import sanitizeHtml from 'sanitize-html'

import './index.scss'

const PrivacyTermsPrivacy = ({ data }) => {

  const htmlOutput = (rawText) => {
    const splitText = rawText.split('\n\n')
    const stringOutput = splitText.filter(line => line && line.length > 0)
      .map((line, index) => (
        `<p class="privacy-terms-privacy__paragraph">${line}</p>`
      )).join('')

    return sanitizeHtml(stringOutput, {
      allowedAttributes: {
        'p': ["class"]
      },
      allowedTags: [ 'b', 'i', 'em', 'strong', 'p', 'br'],
      selfClosing: ['br']
    })
  }

  const subsections = data.sections.map(section =>
    <section className={`privacy-terms-privacy__subsection privacy-terms-privacy__subsection--${section.title.replace(' ', '-')}`} key={`subsection-${section.title.replace(' ', '-')}`}>
      <h3 className="privacy-terms-privacy__subsection__title">{section.title}</h3>
      <div className="privacy-terms-privacy__subsection__text" dangerouslySetInnerHTML={{ __html: htmlOutput(section.text)}}></div>
    </section>
  )

  return (
    <section className="privacy-terms-privacy">
      <div className="privacy-terms-privacy__container">
        <h1 className="privacy-terms-privacy__title">{data.title}</h1>
        <div className="privacy-terms-privacy__text" dangerouslySetInnerHTML={{ __html: htmlOutput(data.text)}}></div>
        {subsections}
      </div>
    </section>
  )
}

export default PrivacyTermsPrivacy
