import React from 'react'

import './index.scss'

const PrivacyTermsHero = ({ data }) => {
  return (
    <section className="privacy-terms-hero">
      <div className="privacy-terms-hero__container">
        <h1 className="privacy-terms-hero__title">{data.title}</h1>
      </div>
    </section>
  )
}

export default PrivacyTermsHero
